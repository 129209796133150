import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/NoteLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const BoxedText = makeShortcode("BoxedText");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "aoc-2020-day-1--report-repair",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#aoc-2020-day-1--report-repair",
        "aria-label": "aoc 2020 day 1  report repair permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`AoC 2020 Day 1 — Report Repair`}</h1>
    <BoxedText mdxType="BoxedText">
      <p>{`Link to the exercise: `}<a parentName="p" {...{
          "href": "https://adventofcode.com/2020/day/1"
        }}>{`https://adventofcode.com/2020/day/1`}</a></p>
    </BoxedText>
    <p>{`Okay, we gotta find 2 numbers in the array summing to 2020 and multiply
them. Easy.`}</p>
    <p>{`We could do a brute force solution by looking at every pair and checking if
it sums to 2020.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`for a in numbers`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`  for b in numbers`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`    if a + b == 2020`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`      return a * b`}</span></code></pre>
    <p>{`Asymptotic time complexity is `}<em parentName="p">{`O(n`}<sup>{`2`}</sup>{`)`}</em>{`, because for every one of
`}<inlineCode parentName="p">{`n`}</inlineCode>{` elements we iterate through all n of our elements, where `}<inlineCode parentName="p">{`n`}</inlineCode>{` is the size
of our input array.`}</p>
    <p>{`We can improve on it, but let’s get the data first.`}</p>
    <p>{`I’m pasting the input array into my code, because I don’t care about text
parsing right now.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "u"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`puzzle1Data = `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`[`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`1287, 1366, 1669, 1724, 1338, 1560, 1328, 1886, 1514, 1863, 1876, 1732, 1544, 1547, 1622, 1891, 1453, 1936, 178, 1398, 1454, 1482, 1585, 1625, 1748, 1888, 1723, 717, 1301, 1840, 1930, 1314, 1458, 1952, 1520, 1994, 1924, 1873, 1283, 1036, 2005, 1987, 1973, 1926, 335, 1316, 1241, 1611, 1593, 1754, 1254, 1768, 1824, 1752, 1559, 1221, 1855, 1907, 1917, 1975, 1782, 1966, 1395, 1681, 1236, 1572, 437, 1294, 1614, 1549, 1769, 1963, 1953, 1708, 1382, 1920, 1884, 1841, 1055, 1799, 1818, 1902, 1541, 1830, 1817, 1939, 1311, 1157, 1997, 1269, 2000, 1573, 1898, 1467, 1929, 1530, 1336, 1599, 1860, 1455, 1944, 1339, 1341, 1874, 1322, 1340, 1583, 1765, 1776, 1304, 1880, 1237, 1770, 1011, 1634, 1343, 1864, 1648, 1588, 933, 1839, 1245, 780, 1671, 1989, 1416, 1268, 1619, 1399, 1638, 1319, 1565, 1318, 1084, 1397, 1645, 1760, 1487, 1892, 1980, 1928, 1808, 1692, 1159, 1531, 1575, 457, 1650, 1308, 1347, 1427, 1148, 1705, 1356, 1519, 1490, 1324, 1387, 1649, 1780, 1361, 1866, 1828, 1274, 1606, 1477, 1956, 734, 1483, 1513, 1215, 1927, 1988, 1686, 1914, 1424, 968, 1949, 1999, 1296, 1615, 1446, 1698, 1959, 1983, 2010, 1984, 1859, 1838, 1680, 1134, 1529, 1552, 1764, 1981, 1862, 1430, 1793, 1901, 1909`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`]`}</span></span></code></pre>
    <p>{`Time for some Unison code. Let’s go through our elements once, and for each
`}<inlineCode parentName="p">{`a`}</inlineCode>{` look for `}<inlineCode parentName="p">{`2020 - a`}</inlineCode>{`. This should give us `}<em parentName="p">{`O(n `}{`*`}{` lookup-time)`}</em>{`, where
lookup time is `}<em parentName="p">{`O(log(n))`}</em>{` for balanced binary trees and `}<em parentName="p">{`amortized/expected
O(1)`}</em>{` for hashmaps.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "u"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk7"
          }}>{`findTwoSummingTo2020`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` List Nat `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`->`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` (Nat, Nat)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`findTwoSummingTo2020 numbers =`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  numbersSet = Set.fromList numbers`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`go`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` List Nat `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`->`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` (Nat, Nat)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  go = cases`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`[]`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`->`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`-- uhh? --`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    head `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`+`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` tail `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`->`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` (0, 0) `}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`-- some implementation --`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  go numbers`}</span></span></code></pre>
    <p>{`We take a list of natural numbers, and return a pair of natural numbers. I
forgot about handling the corner case, though. What if we don’t find
anything? What if we get an empty list?`}</p>
    <p>{`Unison has algebraic effects which he weirdly calls abilities, and there is
an effect called Abort in the standard library, so I could throw an
exception, but that whole algebraic effects schtick forces me to actually
handle it later :/`}</p>
    <p>{`Using `}<inlineCode parentName="p">{`Optional`}</inlineCode>{` will be easier, and probably more elegant here.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "u"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk7"
          }}>{`findTwoSummingTo2020`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` List Nat `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`->`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Optional (Nat, Nat)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`findTwoSummingTo2020 numbers =`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  numbersSet = Set.fromList numbers`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`go`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` List Nat `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`->`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Optional (Nat, Nat)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  go = cases`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`[]`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`->`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` None`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    head `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`+`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` tail `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`->`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      x = 2020 - head`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Set.contains x numbersSet`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`then`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Some (head, x)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`else`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` go tail`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  go numbers`}</span></span></code></pre>
    <p>{`Banging. I added that Optional and a conditional expression, and the code
starts looking proper.`}</p>
    <p>{`This obviously doesn’t work. It doesn’t typecheck, because Unison doesn’t
know what `}<inlineCode parentName="p">{`-`}</inlineCode>{` means.`}</p>
    <figure>
      <pre {...{
        "className": "night-owl-no-italics vscode-highlight",
        "data-language": ""
      }}><code parentName="pre" {...{
          "className": "vscode-highlight-code"
        }}><span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}>{`I'm not sure what - means at line 16, columns 16-17`}</span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}>{`    16 |       x = 2020 - head`}</span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}>{`Whatever it is, it has a type that conforms to .base.Nat ->{𝕖} .base.Nat ->{𝕖} .base.Nat.`}</span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}>{`I found some terms in scope with matching names but different types.`}</span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}>{`If one of these is what you meant, try using the fully qualified name and I might`}</span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}>{`be able to give you a more illuminating error message:`}</span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}>{`  - .base.Float.- : .base.Float -> .base.Float -> .base.Float`}</span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}>{`  - .base.Int.- : .base.Int -> .base.Int -> .base.Int`}</span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}>{`  - .base.Nat.- : .base.Nat -> .base.Nat -> .base.Int`}</span></code></pre>
      <figcaption>output from <code>ucm</code></figcaption>
    </figure>
    <p>{`Really nice error message, my new friend.`}</p>
    <p>{`Unison has at least three types for numbers`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Int`}</inlineCode>{` — signed 64-bit integer`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Nat`}</inlineCode>{` — unsigned 64-bit integer`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Float`}</inlineCode>{` — 64-bit floating point`}</li>
    </ul>
    <p>{`Which are WAY better names than `}<inlineCode parentName="p">{`long long`}</inlineCode>{`, `}<inlineCode parentName="p">{`unsigned long long`}</inlineCode>{` and
`}<inlineCode parentName="p">{`double`}</inlineCode>{`. I didn’t code in C for years, and I’m still salty. Still, I kinda
prefer Rust’s `}<inlineCode parentName="p">{`u64`}</inlineCode>{`, but I digress.`}</p>
    <p>{`There’s no implicit coercion in Unison, so my set of `}<inlineCode parentName="p">{`Nat`}</inlineCode>{`ural numbers and
subtraction aren’t the best friends (`}<inlineCode parentName="p">{`Nat.-`}</inlineCode>{` returns `}<inlineCode parentName="p">{`Int`}</inlineCode>{`). Let’s turn it
all into signed integers. Figuring this out took me more than I want to
admit, but Unison wanted me to add a plus sign in front of every positive
number. Signed integers… oh…`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "u"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`puzzle1Data = List.map Nat.toInt listOfNaturalNumbersFromBefore`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk7"
          }}>{`findTwoSummingTo2020`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` List Int `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`->`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Optional (Int, Int)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`findTwoSummingTo2020 numbers =`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  numbersSet = Set.fromList numbers`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`go`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` List Int `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`->`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Optional (Int, Int)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  go = cases`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`[]`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`->`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` None`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    head `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`+`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` tail `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`->`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      x = `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`+2020`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` - head`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Set.contains x numbersSet`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`then`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Some (head, x)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`else`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` go tail`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  go numbers`}</span></span></code></pre>
    <p>{`This worked. Now what’s left is only multiplying the two numbers I’ve found.
The code before was quite readable, so I’ll make the next function
point-free 🤣`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "u"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk7"
          }}>{`solve`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` List Int `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`->`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Optional Int`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`solve = Optional.map (uncurry (Int.*)) . findTwoSummingTo2020`}</span></span></code></pre>
    <p><inlineCode parentName="p">{`>`}</inlineCode>{` starts watch expression which is conveniently printed by Unison Codebase
Manager on save.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`> solve puzzle1Data`}</span></code></pre>
    <figure>
      <p><img parentName="p" {...{
          "src": "https://user-images.githubusercontent.com/15332326/101247033-26d1ef00-3717-11eb-96ef-3ab47611a853.png",
          "alt": null
        }}></img></p>
      <figcaption>output from <code>ucm</code></figcaption>
    </figure>
    <p>{`Sweet. Advent’s website confirmed my result, and gave me the second part of
the task. Before I get to it, I want to make my code more `}<em parentName="p">{`unisony`}</em>{`.`}</p>
    <p>{`It seems to me that Unison is one `}<a parentName="p" {...{
        "href": "/notes/languages-for-engineering",
        "title": "languages-for-engineering"
      }}>{`languages-for-engineering`}</a>{`. Tests and
documentation are first-class citizens in the language.`}</p>
    <p>{`Here’s how I wrote the docs. You can read more about it at
`}<a parentName="p" {...{
        "href": "https://www.unisonweb.org/docs/documentation"
      }}>{`https://www.unisonweb.org/docs/documentation`}</a>{`.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "u"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`findTwoSummingTo2020.doc = `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`[`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`:`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  \`@findTwoSummingTo2020 numbers\` finds two elements of numbers list`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  summing to `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`2020.`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  Here's a typical `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`example`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`:`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  @`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`[`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`source`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`]`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` findTwoSummingTo2020.examples.ex1`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  ⧩`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  @`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`[`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`evaluate`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`]`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` findTwoSummingTo2020.examples.ex1`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  \`@findTwoSummingTo2020 `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`[]`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`\` is equal to None.`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  Asymptotic time complexity is ϴ(nlogn) `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`or`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` amortized ϴ(n) depending on`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  Unison's builtin Map implementation (balanced tree `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`or`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` hashmap).`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`]`}</span></span></code></pre>
    <figure>
      <p><img parentName="p" {...{
          "src": "https://user-images.githubusercontent.com/15332326/101245638-76f88380-370e-11eb-8af5-171d562a087e.png",
          "alt": null
        }}></img></p>
      <figcaption>documentation for `findTwoSummingTo2020` rendered by `ucm`</figcaption>
    </figure>
    <p>{`And these are my tests:`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "u"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`test>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` findTwoSummingTo2020.tests.one =`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  check (`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    findTwoSummingTo2020 [`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`+2000`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`+1995`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`+25`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`+1234`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`]`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` == Some (`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`+1995`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`+25`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  )`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`test>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` solve1.tests.one = check (solve1 puzzle1Data == Some `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`+691771`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span></code></pre>
    <figure>
      <p><img parentName="p" {...{
          "src": "https://user-images.githubusercontent.com/15332326/101259254-0a8a7e00-3728-11eb-8f5c-4c5744b0989b.png",
          "alt": null
        }}></img></p>
    </figure>
    <hr></hr>
    <p>{`Okay, part two of the puzzle. Now we have to find three numbers summing
to 2020. I don’t have any new, smarter idea, so I’ll just build on top of my
existing solution.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "u"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk7"
          }}>{`findTwoSummingToX`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` List Int `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`->`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Set Int `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`->`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Int `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`->`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Optional (Int, Int)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`findTwoSummingToX numbers numbersSet sum =`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`go`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` List Int `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`->`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Optional (Int, Int)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  go = cases`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`[]`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`->`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` None`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    head `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`+`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` tail `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`->`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      x = sum - head`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Set.contains x numbersSet`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`then`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Some (head, x)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`else`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` go tail`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  go numbers`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk7"
          }}>{`findThreeSummingToX`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` List Int `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`->`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Set Int `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`->`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Int `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`->`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Optional (Int, Int, Int)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`findThreeSummingToX numbers numbersSet sum =`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  findTwo = findTwoSummingToX numbers numbersSet`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  cons = Optional.map . Tuple.Cons`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  List.foldl (acc val `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`->`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`let`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`use`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Int -`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      diff = sum - val`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` isSome acc `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`then`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` acc `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`else`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` (cons val (findTwo diff))`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  ) None numbers`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`solve2 numbers =`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`match`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` findThreeSummingToX numbers (Set.fromList numbers) `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`+2020`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`with`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    Some (a, b, c) `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`->`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Some ((a, b, c), a * b * c)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    None `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`->`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` None`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` solve2 puzzleData`}</span></span></code></pre>
    <p>{`Here it is. I’m quite impressed with this language.`}</p>
    <p>{`Did you, the human reading this, take part in the Advent of Code? Have you
tried any new language? Let me know!`}</p>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .night-owl-no-italics {
background-color: #011627;
color: #d6deeb;
}

.night-owl-no-italics .mtk1 { color: #D6DEEB; }
.night-owl-no-italics .mtk2 { color: #011627; }
.night-owl-no-italics .mtk3 { color: #C792EA; }
.night-owl-no-italics .mtk4 { color: #82B1FF; }
.night-owl-no-italics .mtk5 { color: #ADDB67; }
.night-owl-no-italics .mtk6 { color: #637777; }
.night-owl-no-italics .mtk7 { color: #82AAFF; }
.night-owl-no-italics .mtk8 { color: #F78C6C; }
.night-owl-no-italics .mtk9 { color: #7FDBCA; }
.night-owl-no-italics .mtk10 { color: #FF5874; }
.night-owl-no-italics .mtk11 { color: #FFEB95; }
.night-owl-no-italics .mtk12 { color: #FFCB8B; }
.night-owl-no-italics .mtk13 { color: #7FDBCAFF; }
.night-owl-no-italics .mtk14 { color: #80CBC4; }
.night-owl-no-italics .mtk15 { color: #FF6363; }
.night-owl-no-italics .mtk16 { color: #57EAF1; }
.night-owl-no-italics .mtk17 { color: #5F7E97; }
.night-owl-no-italics .mtk18 { color: #ECC48D; }
.night-owl-no-italics .mtk19 { color: #B2CCD6; }
.night-owl-no-italics .mtk20 { color: #FAD430; }
.night-owl-no-italics .mtk21 { color: #FFFFFF; }
.night-owl-no-italics .mtk22 { color: #FF2C83; }
.night-owl-no-italics .mtk23 { color: #020E14; }
.night-owl-no-italics .mtk24 { color: #D3423E; }
.night-owl-no-italics .mtk25 { color: #EC5F67; }
.night-owl-no-italics .mtk26 { color: #8BD649; }
.night-owl-no-italics .mtk27 { color: #5CA7E4; }
.night-owl-no-italics .mtk28 { color: #A2BFFC; }
.night-owl-no-italics .mtk29 { color: #EF535090; }
.night-owl-no-italics .mtk30 { color: #ADDB67FF; }
.night-owl-no-italics .mtk31 { color: #697098; }
.night-owl-no-italics .mtk32 { color: #FF869A; }
.night-owl-no-italics .mtk33 { color: #DDDDDD; }
.night-owl-no-italics .mtk34 { color: #E0DEC6; }
.night-owl-no-italics .mtk35 { color: #CDEBF7; }
.night-owl-no-italics .mtk36 { color: #D9F5DD; }
.night-owl-no-italics .mtk37 { color: #6AE9F0; }
.night-owl-no-italics .mtk38 { color: #C789D6; }
.night-owl-no-italics .mtk39 { color: #BEC5D4; }
.night-owl-no-italics .mtk40 { color: #31E1EB; }
.night-owl-no-italics .mtk41 { color: #8EACE3; }
.night-owl-no-italics .mtk42 { color: #78CCF0; }
.night-owl-no-italics .mtk43 { color: #FAF39F; }
.night-owl-no-italics .mtk44 { color: #D7DBE0; }
.night-owl-no-italics .mtk45 { color: #7986E7; }
.night-owl-no-italics .mtki { font-style: italic; }
.night-owl-no-italics .mtkb { font-weight: bold; }
.night-owl-no-italics .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      